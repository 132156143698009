var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{attrs:{"items":_vm.displayProducts,"loading":_vm.isLoading,"disabled":_vm.disabled,"item-value":"id","clear-icon":'far fa-times-circle',"search-input":_vm.search,"menu-props":{ bottom: true, offsetY: true },"multiple":_vm.multiple,"label":_vm.label,"no-data-text":_vm.noDataText,"background-color":"white","hide-details":"","no-filter":"","outlined":"","clearable":"","dense":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":function($event){return _vm.$emit('input', _vm.selectedProducts)}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({attrs:{"input-value":data.selected,"small":"","label":""},on:{"click":data.select}},'v-chip',data.attrs,false),[_c('v-chip',{staticClass:"mr-2",attrs:{"color":_vm.getProductStatusColor(data.item.status),"label":"","x-small":"","dark":""},domProps:{"textContent":_vm._s(data.item.statusLabel)}}),(data.item.reference)?_c('span',[_vm._v(" "+_vm._s(data.item.reference)+" ")]):_c('span',[_c('i',[_vm._v(" Sans référence ")])]),_c('span',{staticClass:"mx-2"},[_vm._v(" - ")]),_c('span',[_c('v-menu',{attrs:{"open-on-hover":"","offset-y":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm._f("toCurrencyString")(_vm.getProductFullPrice(data.item)))+" ")])]}}],null,true)},[_c('v-card',[_c('v-card-title',{staticClass:"pa-1 title-1 pl-4 white--text primary"},[_vm._v(" Prix total ")]),_c('v-divider'),_c('v-card-text',{staticClass:"text-right"},[_c('table',[_c('tr',[_c('td',{staticClass:"text-left pr-5"},[_vm._v(" "+_vm._s(data.item.typeLabel)+" ")]),_c('td'),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("toCurrencyString")(data.item.price))+" ")])]),_vm._l((data.item.associated),function(associated){return _c('tr',{key:associated.id},[_c('td',{staticClass:"text-left pr-5"},[_vm._v(" "+_vm._s(associated.typeLabel)+" ")]),_c('td',[_vm._v(" +")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("toCurrencyString")(associated.price))+" ")])])}),_c('tr',[_c('td',{staticClass:"text-left pr-5"},[_vm._v(" Total ")]),_c('td'),_c('td',{staticClass:"text-right",staticStyle:{"border-top":"1px solid black"}},[_vm._v(" "+_vm._s(_vm._f("toCurrencyString")(_vm.getProductFullPrice(data.item)))+" ")])])],2)])],1)],1)],1)],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-action',{staticStyle:{"width":"14%"}},[_c('v-chip',{attrs:{"color":_vm.getProductStatusColor(item.status),"label":"","small":"","dark":""},domProps:{"textContent":_vm._s(item.statusLabel)}})],1),_c('v-list-item-content',{staticStyle:{"width":"86%"}},[_c('v-list-item-title',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"x-small":"","label":""}},on),[(item.reference)?_c('span',[_vm._v(" "+_vm._s(item.reference)+" ")]):_c('span',[_c('i',[_vm._v(" Sans référence ")])])])]}}],null,true)},[_vm._v(" Référence ")]),_c('v-menu',{attrs:{"open-on-hover":"","offset-y":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm._f("toCurrencyString")(_vm.getProductFullPrice(item)))+" ")])]}}],null,true)},[_c('v-card',[_c('v-card-title',{staticClass:"pa-1 title-1 pl-4 white--text primary"},[_vm._v(" Prix total ")]),_c('v-divider'),_c('v-card-text',{staticClass:"text-right"},[_c('table',[_c('tr',[_c('td',{staticClass:"text-left pr-5"},[_vm._v(" "+_vm._s(item.typeLabel)+" ")]),_c('td'),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("toCurrencyString")(item.price))+" ")])]),_vm._l((item.associated),function(associated){return _c('tr',{key:associated.id},[_c('td',{staticClass:"text-left pr-5"},[_vm._v(" "+_vm._s(associated.typeLabel)+" ")]),_c('td',[_vm._v(" +")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("toCurrencyString")(associated.price))+" ")])])}),_c('tr',[_c('td',{staticClass:"text-left pr-5"},[_vm._v(" Total ")]),_c('td'),_c('td',{staticClass:"text-right",staticStyle:{"border-top":"1px solid black"}},[_vm._v(" "+_vm._s(_vm._f("toCurrencyString")(_vm.getProductFullPrice(item)))+" ")])])],2)])],1)],1)],1),_c('v-list-item-subtitle',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[(item.program.title)?_c('span',[_vm._v(" "+_vm._s(item.program.title)+" ")]):_c('span',[_c('i',[_vm._v(" Programme sans nom ")])])])]}}],null,true)},[_vm._v(" Nom du programme ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[(item.program.city)?_c('span',[_vm._v(" "+_vm._s(item.program.city)+" ")]):_c('span',[_c('i',[_vm._v(" Sans ville ")])])])]}}],null,true)},[_vm._v(" Ville ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[(item.program.zip)?_c('span',[_vm._v(" ("+_vm._s(item.program.zip)+") ")]):_c('span',[_c('i',[_vm._v(" Sans code postal ")])])])]}}],null,true)},[_vm._v(" Code postal ")])],1)],1)]}}]),model:{value:(_vm.selectedProducts),callback:function ($$v) {_vm.selectedProducts=$$v},expression:"selectedProducts"}})}
var staticRenderFns = []

export { render, staticRenderFns }