<template>
    <v-select v-model="selectedFinalite" :items="finalites" :loading="isLoading" :disabled="disabled" item-value="key" item-text="value" clear-icon="far fa-times-circle" :menu-props="{ bottom: true, offsetY: true }" @change="$emit('input', selectedFinalite)" :multiple="multiple" :label="label" :placeholder="placeholder" background-color="white" hide-details no-filter outlined clearable dense>
        <template v-slot:selection="data">
            <v-chip v-if="data.index === 0" color="accent" small label dark>
                {{ data.item.value }}
            </v-chip>

            <span v-if="data.index === 1" class="grey--text text-caption">
                (+{{ selectedFinalite.length - 1 }} autres)
            </span>
        </template>

        <template v-slot:item="{ item }">
            <v-list-item-content>
                <v-list-item-title>
                    <v-chip color="accent" label small dark>
                        {{ item.value }}
                    </v-chip>
                </v-list-item-title>
            </v-list-item-content>
        </template>
    </v-select>
</template>

<script>
export default {
    name: 'FinaliteSelect',

    props: {
        value: { required: true },
        label: { type: String },
        placeholder: { type: String },
        disabled: { type: Boolean, default: false },
        multiple: { type: Boolean, default: false }
    },

    data: () => ({
        selectedFinalite: null,
        isLoading: false
    }),

    computed: {
        finalites() {
            return (this.getParameter(this.getConfig('contacts.finalite_parameter')) || []).filter((i) => i.key !== '0').map((i) => ({ key: parseInt(i.key), value: i.value }));
        }
    },

    created() {
        this.selectedFinalite = this.value;
    }
};
</script>