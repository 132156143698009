<template>
    <v-dialog v-model="isOpen" width="700px">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    <template v-if="isOption">
                        Poser une option
                    </template>
                    <template v-else>
                        Enregistrer une réservation
                    </template>
                </v-toolbar-title>

                <v-spacer />

                <v-menu v-if="isOption" top open-on-hover offset-y max-width="500px">
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                            fas fa-question-circle
                        </v-icon>
                    </template>

                    <v-card>
                        <v-card-title class="py-1 grey white--text">
                            Aide
                        </v-card-title>
                        <v-card-text class="pt-4">
                            Le lot peut être optionné sur une durée maximale de {{ getConfig('options.duration', 5) }} jours.
                            <br>
                            Si vous souhaitez disposer d'un délai supérieur en vue de finaliser une réservation, merci de prendre contact directement avec nous.

                            <template v-if="getConfig('options.denonciation_must_be_validated', false)">
                                <br>
                                Vous ne pouvez poser une option que sur un contact que vous avez dénoncé au préalable et qui a été validé. <br>
                                Vous ne pouvez poser une option que sur un programme qui vous est affecté en ce moment.
                            </template>
                        </v-card-text>
                    </v-card>
                </v-menu>
            </v-toolbar>

            <v-divider />

            <v-card-text class="pt-4 px-4">
                <ProgramAutocomplete ref="programAutocomplete" label="Programme" v-model="option.programId" :disabled="!!initProgramId" hideArchived noDynamicSearch :multiple="false" class="mb-4" />

                <ProductAutocomplete ref="productAutocomplete" label="Lot" v-model="option.productId" :disabled="!!initProductId" :multiple="false" noDynamicSearch v-show="!!option.programId" class="mb-4" />

                <v-divider class="mb-4" />

                <ContactAutocomplete ref="contactAutocomplete" v-model="option.visitorId" :disabled="!!initVisitorId" :only-denonciated="getConfig('options.denonciation_must_be_validated', false)" :only-denonciated-program-id="getConfig('options.denonciation_must_be_validated_for_program', false) ? option.programId : null" class="mb-4" />

                <v-textarea v-model="option.comment" label="Commentaire" rows="2" hide-details outlined dense />
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit()" :disabled="!option.visitorId || !option.productId" color="primary" depressed small>
                    <template v-if="isOption">
                        Poser l'option
                    </template>
                    <template v-else>
                        Enregistrer la réservation
                    </template>
                </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" depressed small>
                    Annuler
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import ContactAutocomplete from '../widgets/ContactAutocomplete.vue';
import ProductAutocomplete from '../widgets/ProductAutocomplete.vue';
import ProgramAutocomplete from '../widgets/ProgramAutocomplete.vue';

export default {
    name: 'CreateOptionDialog',

    props: {
        initVisitorId: { type: Number },
        initProgramId: { type: Number },
        initProductId: { type: Number },
        isOption: { type: Boolean, required: true }
    },

    components: {
        ContactAutocomplete,
        ProductAutocomplete,
        ProgramAutocomplete
    },

    data: () => ({
        isOpen: false,

        option: {
            visitorId: null,
            programId: null,
            productId: null
        },

        comment: null
    }),

    methods: {
        async submit() {
            if (this.isOption) {
                this.createOption();
            } else {
                this.createSale();
            }
        },

        async createOption() {
            try {
                this.setLoading(true);

                const body = { option: this.option };
                const { success, err } = await this.repos.options.createOption(body);
                if (success) {
                    this.isOpen = false;
                    this.$notify({
                        title: 'Succès',
                        text: 'L\'option a bien été créée',
                        type: 'success'
                    });
                    this.$emit('reload');
                } else {
                    this.$notify({
                        title: 'Erreur',
                        text: err,
                        type: 'error',
                        duration: 15000
                    });
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la création de l\'option',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async createSale() {
            try {
                this.setLoading(true);

                const body = { sale: this.option };
                const { success, saleId, err } = await this.repos.sales.createSale(body);
                if (success) {
                    this.isOpen = false;
                    this.$notify({
                        title: 'Succès',
                        text: 'La réservation a bien été créée',
                        type: 'success'
                    });
                    this.$router.push(`/reservations/${saleId}`);
                } else {
                    this.$notify({
                        title: 'Erreur',
                        text: err,
                        type: 'error',
                        duration: 15000
                    });
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la création de la réservation',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    },

    watch: {
        isOpen() {
            if (this.isOpen) {
                this.$nextTick(() => {
                    if (this.initProgramId && this.initVisitorId) {
                        this.$refs.productAutocomplete.fetchProducts({ limit: 1000, statuses: 0, programs: this.initProgramId });
                    } else if (this.initVisitorId) {
                        const query = {
                            limit: 1000,
                            atLeastOneProductAvailable: 1
                        };
                        if (this.getConfig('options.denonciation_must_be_validated', false) && this.getConfig('options.denonciation_must_be_validated_for_program', false)) {
                            query.onlyDenonciated = 1;
                            if (this.option.visitorId) {
                                query.onlyDenonciatedVisitorId = this.option.visitorId;
                            }
                        }
                        this.$refs.programAutocomplete.fetchPrograms(query);
                    } else if (this.initProgramId) {
                        this.$refs.productAutocomplete.fetchProducts({ limit: 1000, statuses: 0, programs: this.initProgramId });
                    } else {
                        const query = {
                            limit: 1000,
                            atLeastOneProductAvailable: 1
                        };
                        if (this.getConfig('options.denonciation_must_be_validated', false) && this.getConfig('options.denonciation_must_be_validated_for_program', false)) {
                            query.onlyDenonciated = 1;
                        }
                        this.$refs.programAutocomplete.fetchPrograms(query);
                    }
                });
            }
        },

        'option.programId'() {
            if (this.isOpen) {
                this.option.productId = this.initProductId;
                if (this.$refs.productAutocomplete) {
                    this.$refs.productAutocomplete.reset();
                }

                if (!this.initProgramId) {
                    if (this.option.programId) {
                        this.$refs.productAutocomplete.fetchProducts({ limit: 1000, statuses: 0, programs: this.option.programId });
                    } else {
                        this.$refs.productAutocomplete.reset();
                    }
                }
            }
        },

        'option.visitorId'() {
            if (this.isOpen) {
                if (!this.option.programId) {
                    const query = {
                        limit: 1000,
                        atLeastOneProductAvailable: 1
                    };
                    if (this.getConfig('options.denonciation_must_be_validated', false)) {
                        query.onlyDenonciated = 1;
                        if (this.getConfig('options.denonciation_must_be_validated_for_program', false)) {
                            if (this.option.visitorId) {
                                query.onlyDenonciatedVisitorId = this.option.visitorId;
                            }
                        }
                    }
                    this.$refs.programAutocomplete.fetchPrograms(query);
                }
            }
        }
    },

    created() {
        if (this.initVisitorId) {
            this.option.visitorId = this.initVisitorId;
        }
        if (this.initProgramId) {
            this.option.programId = this.initProgramId;
        }
        if (this.initProductId) {
            this.option.productId = this.initProductId;
        }
    }
};
</script>
