<template>
    <v-autocomplete v-model="selectedProducts" :items="displayProducts" :loading="isLoading" :disabled="disabled" item-value="id" :clear-icon="'far fa-times-circle'" :search-input.sync="search" :menu-props="{ bottom: true, offsetY: true }" @change="$emit('input', selectedProducts)" :multiple="multiple" :label="label" :no-data-text="noDataText" background-color="white" hide-details no-filter outlined clearable dense>
        <template v-slot:selection="data">
            <v-chip v-bind="data.attrs" :input-value="data.selected" @click="data.select" small label>
                <v-chip v-text="data.item.statusLabel" :color="getProductStatusColor(data.item.status)" label x-small dark class="mr-2" />

                <span v-if="data.item.reference">
                    {{ data.item.reference }}
                </span>
                <span v-else>
                    <i> Sans référence </i>
                </span>

                <span class="mx-2">
                    -
                </span>

                <span>
                    <v-menu open-on-hover offset-y top>
                        <template v-slot:activator="{ on }">
                            <span v-on="on">
                                {{ getProductFullPrice(data.item) | toCurrencyString() }}
                            </span>
                        </template>

                        <v-card>
                            <v-card-title class="pa-1 title-1 pl-4 white--text primary"> Prix total </v-card-title>

                            <v-divider />

                            <v-card-text class="text-right">
                                <table>
                                    <tr>
                                        <td class="text-left pr-5">
                                            {{ data.item.typeLabel }}
                                        </td>

                                        <td> </td>

                                        <td class="text-right">
                                            {{ data.item.price | toCurrencyString() }}
                                        </td>
                                    </tr>

                                    <tr v-for="associated of data.item.associated" :key="associated.id">
                                        <td class="text-left pr-5">
                                            {{ associated.typeLabel }}
                                        </td>
                                        <td> +</td>

                                        <td class="text-right">
                                            {{ associated.price | toCurrencyString() }}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="text-left pr-5">
                                            Total
                                        </td>

                                        <td> </td>

                                        <td class="text-right" style="border-top: 1px solid black">
                                            {{ getProductFullPrice(data.item) | toCurrencyString() }}
                                        </td>
                                    </tr>
                                </table>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                </span>
            </v-chip>
        </template>
        <template v-slot:item="{ item }">
            <v-list-item-action style="width: 14%">
                <v-chip v-text="item.statusLabel" :color="getProductStatusColor(item.status)" label small dark />
            </v-list-item-action>

            <v-list-item-content style="width: 86%">
                <v-list-item-title>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-chip x-small label v-on="on">
                                <span v-if="item.reference">
                                    {{ item.reference }}
                                </span>
                                <span v-else>
                                    <i> Sans référence </i>
                                </span>
                            </v-chip>
                        </template>
                        Référence
                    </v-tooltip>

                    <v-menu open-on-hover offset-y top>
                        <template v-slot:activator="{ on }">
                            <span v-on="on">
                                {{ getProductFullPrice(item) | toCurrencyString() }}
                            </span>
                        </template>

                        <v-card>
                            <v-card-title class="pa-1 title-1 pl-4 white--text primary"> Prix total </v-card-title>

                            <v-divider />

                            <v-card-text class="text-right">
                                <table>
                                    <tr>
                                        <td class="text-left pr-5">
                                            {{ item.typeLabel }}
                                        </td>

                                        <td> </td>

                                        <td class="text-right">
                                            {{ item.price | toCurrencyString() }}
                                        </td>
                                    </tr>

                                    <tr v-for="associated of item.associated" :key="associated.id">
                                        <td class="text-left pr-5">
                                            {{ associated.typeLabel }}
                                        </td>
                                        <td> +</td>

                                        <td class="text-right">
                                            {{ associated.price | toCurrencyString() }}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="text-left pr-5">
                                            Total
                                        </td>

                                        <td> </td>

                                        <td class="text-right" style="border-top: 1px solid black">
                                            {{ getProductFullPrice(item) | toCurrencyString() }}
                                        </td>
                                    </tr>
                                </table>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                </v-list-item-title>

                <v-list-item-subtitle>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <span v-on="on">
                                <span v-if="item.program.title">
                                    {{ item.program.title }}
                                </span>
                                <span v-else>
                                    <i> Programme sans nom </i>
                                </span>
                            </span>
                        </template>
                        Nom du programme
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <span v-on="on">
                                <span v-if="item.program.city">
                                    {{ item.program.city }}
                                </span>
                                <span v-else>
                                    <i> Sans ville </i>
                                </span>
                            </span>
                        </template>
                        Ville
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <span v-on="on">
                                <span v-if="item.program.zip">
                                    ({{ item.program.zip }})
                                </span>
                                <span v-else>
                                    <i> Sans code postal </i>
                                </span>
                            </span>
                        </template>
                        Code postal
                    </v-tooltip>
                </v-list-item-subtitle>
            </v-list-item-content>
        </template>
    </v-autocomplete>
</template>

<script>
import productsMixin from '../../mixins/products.js';

export default {
    name: 'ProgramAutocomplete',

    mixins: [productsMixin],

    props: {
        value: { required: true },
        label: { type: String, default: 'Lots' },
        disabled: { type: Boolean, default: false },
        multiple: { type: Boolean, default: false },
        noDynamicSearch: { type: Boolean, default: false },
        programs: { type: Array, default: () => ([]) }
    },

    data: () => ({
        selectedProducts: null,
        products: [],
        displayProducts: [],
        isLoading: false,
        search: null,
        searchTimeout: null
    }),

    computed: {
        noDataText() {
            if (this.noDynamicSearch) {
                return 'Aucun lot disponible pour ce programme';
            } else {
                return 'Commencez à taper pour lancer une recherche';
            }
        }
    },

    methods: {
        async fetchProducts(query) {
            try {
                this.isLoading = true;
                query.include = 'associated';
                const { products } = await this.repos.products.getProducts(query);
                this.products = products;
                this.displayProducts = products;
            } catch (err) {
                console.error(err);
            } finally {
                this.isLoading = false;
            }
        },

        reset() {
            this.products = [];
            this.selectedProducts = null;
        }
    },

    watch: {
        search(search) {
            if (this.noDynamicSearch) {
                if (typeof search !== 'string' || search.length.length < 3) {
                    this.displayProducts = this.products;
                } else {
                    this.displayProducts = this.products.filter((p) => {
                        return p.reference.toLowerCase().includes(search.toLocaleLowerCase());
                    });
                }
                return;
            }

            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                if (typeof search !== 'string' || search.length.length < 3) {
                    return;
                }
                if (this.isLoading) {
                    return;
                }

                const query = {
                    limit: 15,
                    quickSearch: search
                };
                if (this.programs.length > 0) {
                    query.programs = this.programs.join(',');
                }
                this.fetchProducts(query);
            }, 500);
        }
    },

    created() {
        this.selectedProducts = this.value;
        if ((Array.isArray(this.selectedProducts) && this.selectedProducts.length > 0) || typeof this.selectedProducts === 'number') {
            const products = Array.isArray(this.selectedProducts) ? this.selectedProducts.join(',') : [this.selectedProducts];
            this.fetchProducts({ products });
        }
    }
};
</script>