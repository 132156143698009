<template>
    <v-autocomplete v-model="selectedPrograms" :items="displayPrograms" :loading="isLoading" :disabled="disabled" item-value="id" clear-icon="far fa-times-circle" :search-input.sync="search" @focus="resetSearch()" :menu-props="{ bottom: true, offsetY: true }" @change="$emit('input', selectedPrograms)" :multiple="multiple" :label="label" :placeholder="placeholder" no-data-text="Commencez à taper pour lancer une recherche" background-color="white" :error-messages="errors" :hide-details="!failed" no-filter outlined clearable dense>
        <template v-slot:selection="data">
            <v-chip v-if="data.index === 0" small label>
                <v-avatar left>
                    <v-img :src="getProgramPhotoUrl(data.item)" />
                </v-avatar>
                <span v-if="data.item.title">
                    {{ data.item.title }}
                </span>
                <span v-else>
                    <i> Sans nom </i>
                </span>
            </v-chip>

            <span v-if="data.index === 1" class="grey--text text-caption">
                (+{{ selectedPrograms.length - 1 }} autres)
            </span>
        </template>

        <template v-slot:prepend>
            <slot name="prepend" />
        </template>

        <template v-slot:item="{ item }">
            <v-list-item-avatar>
                <v-img :src="getProgramPhotoUrl(item)" />
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>
                    <span v-if="item.title">
                        {{ item.title }}
                    </span>
                    <span v-else>
                        <i> Sans nom </i>
                    </span>
                </v-list-item-title>
                <v-list-item-subtitle>
                    <span v-if="item.city">
                        {{ item.city }}
                    </span>

                    <span v-if="item.zip">
                        ({{ item.zip }})
                    </span>
                </v-list-item-subtitle>
            </v-list-item-content>
        </template>
    </v-autocomplete>
</template>

<script>
export default {
    name: 'ProgramAutocomplete',

    props: {
        value: { required: true },
        label: { type: String },
        placeholder: { type: String },
        disabled: { type: Boolean, default: false },
        multiple: { type: Boolean, default: false },
        noDynamicSearch: { type: Boolean, default: false },
        initPrograms: { type: Array },
        errors: { type: Array },
        failed: { type: Boolean, default: false },
        onlyDenonciated: { type: Boolean, default: false },
        hideArchived: { type: Boolean, default: false },
        onlyDenonciatedVisitorId: { type: Number }
    },

    data: () => ({
        selectedPrograms: null,
        programs: [],
        displayPrograms: [],
        isLoading: false,
        search: null,
        searchTimeout: null
    }),

    watch: {
        selectedPrograms() {
            // this.$refs.inputPartners.focus();
            this.sortPrograms();
        },

        value() {
            this.selectedPrograms = this.value;
        },

        search(search) {
            if (this.noDynamicSearch) {
                if (typeof search !== 'string' || search.length < 1) {
                    this.displayPrograms = this.programs;
                } else {
                    const normalizedSearch = search.toLocaleLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '');
                    this.displayPrograms = this.programs.filter((p) => {
                        const title = p.title.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '');
                        const city = p.city.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '');
                        const zip = p.zip.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '');

                        return title.includes(normalizedSearch) || title.replace('-', ' ').includes(normalizedSearch) || title.includes(normalizedSearch.replace('-', ' ')) ||
                            city.includes(normalizedSearch) || city.replace('-', ' ').includes(normalizedSearch) || city.includes(normalizedSearch.replace('-', ' ')) ||
                            zip.includes(normalizedSearch) || zip.replace('-', ' ').includes(normalizedSearch) || zip.includes(normalizedSearch.replace('-', ' '));
                    });
                }
                this.sortPrograms();
                return;
            }

            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                if (typeof search !== 'string' || search.length.length < 3) {
                    return;
                }
                if (this.isLoading) {
                    return;
                }

                this.isLoading = true;
                const query = {
                    limit: 5,
                    quickSearch: search
                };
                this.fetchPrograms(query);
            }, 500);
        },

        initPrograms() {
            this.displayPrograms = this.initPrograms;
            this.sortPrograms();
        }
    },

    computed: {
        noDataText() {
            if (this.noDynamicSearch) {
                return 'Aucun programme disponible';
            } else {
                return 'Commencez à taper pour lancer une recherche';
            }
        }
    },

    methods: {
        async fetchPrograms(query) {
            try {
                query.include = 'photos';
                this.isLoading = true;
                if (this.onlyDenonciated) {
                    query.onlyDenonciated = 1;
                    if (this.onlyDenonciatedVisitorId) {
                        query.onlyDenonciatedVisitorId = this.onlyDenonciatedVisitorId;
                    }
                }
                if (this.hideArchived) {
                    query.archived = 0;
                }
                const { programs } = await this.repos.programs.getPrograms(query);
                this.programs = programs;
                this.displayPrograms = programs;
                this.sortPrograms();
            } catch (err) {
                console.error(err);
            } finally {
                this.isLoading = false;
            }
        },

        getProgramPhotoUrl(program) {
            if (Array.isArray(program.photos) && program.photos.length > 0) {
                return program.photos[0].info.url;
            }
            return 'https://cdn.shopify.com/s/files/1/1661/5755/products/property-placeholder_grande.jpg?v=1482778691';
        },

        resetSearch() {
            this.search = null;
            this.displayPrograms = this.programs;
            this.sortPrograms();
        },

        reset() {
            this.selectedPrograms = [];
        },

        sortPrograms() {
            if (Array.isArray(this.selectedPrograms)) {
                this.displayPrograms.sort((a, b) => {
                    const isASelected = this.selectedPrograms.find((p) => p === a.id);
                    const isBSelected = this.selectedPrograms.find((p) => p === b.id);
                    if (isASelected && !isBSelected) {
                        return -1;
                    } else if (isBSelected && !isASelected) {
                        return 1;
                    } else {
                        return a.title.localeCompare(b.title);
                    }
                });
            }
        }
    },

    created() {
        this.selectedPrograms = this.value;
        if ((Array.isArray(this.selectedPrograms) && this.selectedPrograms.length > 0) || typeof this.selectedPrograms === 'number') {
            const programs = Array.isArray(this.selectedPrograms) ? this.selectedPrograms : [this.selectedPrograms];
            this.fetchPrograms({ programs });
        }
        if (this.initPrograms) {
            this.programs = this.initPrograms;
            this.displayPrograms = this.initPrograms;
            this.sortPrograms();
        }
    }
};
</script>