export default {
    data: () => ({
        productStatusIcons: [
            { status: 0, color: 'green' },
            { status: 2, color: 'orange lighten-1' },
            { status: 7, color: 'orange darken-2' },
            { status: 3, color: 'red lighten-2' },
            { status: 30, color: 'grey darken-1' },
            { status: 4, color: 'red lighten-2' },
            { status: 5, color: 'red lighten-2' },
            { status: 200, color: 'teal' }
        ],

        productTypeIcons: [
            { type: 1, icon: 'fas fa-building' },
            { type: 2, icon: 'fas fa-home' },
            { type: 5, icon: 'fas fa-store-alt' },
            { type: 12, icon: 'fas fa-city' },
            { type: 21, icon: 'fas fa-building' },
            { type: 23, icon: 'fas fa-store' },
            { type: 22, icon: 'fas fa-store' },
            { type: 24, icon: 'fas fa-store' },
            { type: 26, icon: 'fas fa-compress' },
            { type: 27, icon: 'fas fa-compress' },
            { type: 31, icon: 'fas fa-square' },
            { type: 41, icon: 'fas fa-warehouse' },
            { type: 43, icon: 'fas fa-parking' },
            { type: 44, icon: 'fas fa-parking' },
            { type: 51, icon: 'fas fa-compress' }
        ],

        productActivities: [
            { id: 'none', label: 'Aucune' },
            { id: 'options', label: 'Options' },
            { id: 'sales', label: 'Réservations' }
        ]
    }),

    methods: {
        getProductStatusColor(status) {
            const item = this.productStatusIcons.find((a) => a.status === status);
            if (item) {
                return item.color;
            } else {
                console.warn(`Unknown products status: "${status}"`);
                return '';
            }
        },

        getProductTypeIcon(type) {
            const item = this.productTypeIcons.find((a) => a.type === type);
            if (item) {
                return item.icon;
            } else {
                console.warn(`Unknown products type: "${type}"`);
                return '';
            }
        },

        getProductFullPrice(product, field = 'price') {
            let price = isNaN(product[field]) ? 0 : product[field];
            if (Array.isArray(product.associated)) {
                for (const associated of product.associated) {
                    if (!isNaN(associated[field])) {
                        price += associated[field];
                    }
                }
            }

            return price;
        },

        getFiscaliteColor(fiscalite) {
            const map = this.getConfig('products.defiscalisation_colors', {});
            return map[fiscalite];
        }
    }
};
