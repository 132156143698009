<template>
    <v-dialog v-model="isOpen" width="680px">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Sélection contact
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider />

            <v-card-text class="pt-4 px-4">
                <v-list>
                    <v-subheader>
                        <p v-if="contacts.length === 1">
                            Un contact déjà existant correspond à votre saisie,
                            <b> sélectionner </b> ce contact ou bien créer un <b> nouveau </b> contact.
                        </p>
                        <p v-else>
                            Plusieurs contacts déjà existants correspondent à votre saisie,
                            <b> sélectionner </b> le contact concerné ou bien créer un <b> nouveau </b> contact.
                        </p>
                    </v-subheader>

                    <v-list-item-group v-model="partnerContactId">
                        <v-list-item v-for="contact of contacts" :key="contact.id" :value="contact.id">
                            <v-list-item-avatar class="blue white--text text-uppercase headline">
                                {{ contact.name.slice(0, 1) }}
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ contact.name }} {{ contact.firstname }} {{ contact.email }}
                                </v-list-item-title>

                                <v-list-item-subtitle>
                                    {{ contact.mobile }} {{ contact.phone }} <br>
                                    {{ contact.address }} {{ contact.city }} {{ contact.zip }} {{ contact.country }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit(false)" :disabled="!partnerContactId" color="primary" depressed small>
                    Sélectionner
                </v-btn>

                <v-btn @click="submit(true)" color="primary" depressed small>
                    Nouveau
                </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" depressed small>
                    Annuler
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: 'ContactPartnerSelectionDialog',

    data: () => ({
        isOpen: false,
        contacts: [],
        partnerContactId: null
    }),

    methods: {
        open(contacts) {
            this.contacts = contacts;
            if (this.contacts.length === 1) {
                this.partnerContactId = this.contacts[0].id;
            }
            this.isOpen = true;
        },

        async submit(forceCreation) {
            if (forceCreation) {
                this.$emit('partner-selection-submit', null);
            } else {
                this.$emit('partner-selection-submit', this.partnerContactId);
            }
        }
    }
};
</script>
